import { IScheduleSession } from 'store/coachStores/ScheduleSessionStore';
import api from './index';
import { COACH_ROLE, COACH_ROLES } from 'store/coachStores/CoachStore';

interface FeedbackProps {
  name: string;
  feedback: string;
  comment: string;
}

export const submitAnswerFeedback = ({ name, feedback, comment }: FeedbackProps) =>
  api.post(`/mastery/answers_to_be_graded/${name}/feedback/`, {
    feedback,
    comment,
  });

export const fetchOtherActivities = ({ name }: { name: string }) => api.get(`/mastery/other_activities/${name}/`);

interface GetActivityProps {
  name: string;
}

export const getAnswerToBeGraded = ({ name }: GetActivityProps) => api.get(`/mastery/answers_to_be_graded/${name}/`);

export const getPreviousAnswer = ({ answerName, page }: { answerName: string; page: number }) =>
  api.get(`/mastery/previous_answer/${answerName}/`, { page });

export const listAnswersToBeGraded = (
  batchStudent?: string,
  pageSize?: number,
  daysBefore?: number,
  page?: number,
  sessionName?: string
) => api.get(`/mastery/answers_to_be_graded/`, { batchStudent, pageSize, daysBefore, page, sessionName });

export const listCourses = ({ subject }: { subject?: string }) => api.get('/courses/', { subject });

export const listUnits = ({ page = 1, pageSize = 50, q }: { page: number; pageSize?: number; q?: string }) =>
  api.get(`units/`, { q, page, pageSize });

interface fetchSprintProps {
  sprint_name: string;
}

export const fetchSprint = ({ sprint_name }: fetchSprintProps) => api.get(`/sprints/${sprint_name}/`);

export const fetchStudentMasteries = ({
  batchStudent,
  daysBefore,
  pageNumber,
}: {
  batchStudent: string;
  daysBefore: number;
  pageNumber: number;
}) => api.get('/student_mastery/', { batchStudent, page: pageNumber, daysBefore });

interface GetSprintProps {
  courseName: string;
  pageNumber?: number;
  q?: string;
}

export const listCourseSprints = ({ courseName, pageNumber, q }: GetSprintProps) =>
  api.get(`/courses/${courseName}/sprints/`, { page: pageNumber, q });

interface GetSprintsProps {
  pageNumber?: number;
  q?: string;
  sameUnitSprintName?: string;
  isTournament?: boolean;
}

export const listSprints = ({ pageNumber, q, sameUnitSprintName, isTournament }: GetSprintsProps) =>
  api.get(`/sprints/`, { page: pageNumber, q, sameUnitSprintName, isTournament });

export const nextSprint = ({ name, level }: { name: string; level?: string }) =>
  api.get(`/sprints/${name}/next/`, { level });

export const prevSprint = ({ name, level }: { name: string; level?: string }) =>
  api.get(`/sprints/${name}/prev/`, { level });

export const createTestSession = ({ sprintName }: { sprintName: string }) =>
  api.post(`my/tutor_sessions/create-test-session/`, { sprintName });

export const createSession = ({
  sprintName,
  batchName,
  dateTime,
  studentUsernames,
  isCatchupSession = false,
  subject,
  additionalNotes,
}: {
  sprintName: string;
  batchName?: string;
  dateTime: string;
  studentUsernames?: string[];
  isCatchupSession?: boolean;
  subject?: string;
  additionalNotes?: string;
}) =>
  api.post(`my/tutor_sessions/create-session/`, {
    sprintName,
    batchName,
    startAt: dateTime,
    studentUsernames,
    isCatchupSession,
    subject,
    additionalNotes,
  });

export const changeSessionSprint = ({
  sprintName,
  sessionName,
  reason,
}: {
  sprintName: string;
  sessionName: string;
  reason: string;
}) =>
  api.post(`my/tutor_sessions/${sessionName}/change-session_sprint/`, {
    sprintName,
    reason,
  });

export const changeSessionCoach = ({
  sessionName,
  reason,
  coachUsername,
}: {
  sessionName: string;
  coachUsername: string;
  reason: string;
}) =>
  api.post(`my/tutor_sessions/${sessionName}/change-session_coach/`, {
    coachUsername,
    reason,
  });

export const createTournamentSession = ({
  sprintName,
  batchNames,
  dateTime,
}: {
  sprintName: string;
  batchNames: string[];
  dateTime: string;
}) =>
  api.post(`my/tutor_sessions/create-tournament-session/`, {
    sprintName,
    batchNames,
    startAt: dateTime,
  });

export const createOneOneSession = ({
  sprintName,
  studentUsername,
  dateTime,
}: {
  sprintName: string;
  studentUsername: string;
  dateTime: string;
}) => api.post(`my/tutor_sessions/create-session/`, { sprintName, studentUsername, startAt: dateTime });

export const fetchStudentDetails = ({
  batchStudent,
  daysBefore,
  status,
}: {
  batchStudent: string;
  daysBefore: number;
  status?: string;
}) => {
  return api.get(`/batch_students/${batchStudent}/`, { daysBefore, status });
};

export const fetchStudentBatchHistory = ({ batchStudent }: { batchStudent: string }) => {
  return api.get(`/batch_students/${batchStudent}/batch-history/`);
};

export const fetchStudentInsightCallBookingLink = ({ batchStudent }: { batchStudent: string }) => {
  return api.get(`/batch_students/${batchStudent}/insight-call-links/`);
};

export const fetchStudentPartialPayLink = ({ batchStudent }: { batchStudent: string }) => {
  return api.get(`/batch_students/${batchStudent}/partial-pay-link/`);
};

export const fetchStudentRenewalPaymentLink = ({ batchStudent }: { batchStudent: string }) => {
  return api.get(`/batch_students/${batchStudent}/renewal-payment-link/`);
};

export const fetchStudentPartialPayLinks = ({ usernames }: { usernames: string[] }) => {
  return api.get(`/batch_students/partial-pay-links/?usernames=${usernames}`);
};
export const fetchInsightCallLinks = ({ usernames }: { usernames: string[] }) => {
  return api.get(`/batch_students/insight-call-links/?usernames=${usernames}`);
};

export const fetchHasSubscriptionExpired = ({ usernames }: { usernames: string[] }) => {
  return api.get(`/student/has-subscription-expired/?usernames=${usernames}`);
};

export const sendPartialPaymentNotification = ({
  batchStudent,
  alternateNumber,
}: {
  batchStudent: string;
  alternateNumber?: string;
}) => {
  return api.post(`/batch_students/${batchStudent}/partial-pay-notification/`, { alternateNumber });
};

export const sendInsightCallNotification = ({
  batchStudent,
  alternateNumber,
}: {
  batchStudent: string;
  alternateNumber?: string;
}) => {
  return api.post(`/batch_students/${batchStudent}/insight-call-notification/`, { alternateNumber });
};

export const fetchStudents = ({ q, batchName }: { q?: string; batchName?: string }) => {
  return api.get(`/batch_students/`, { q, batch: batchName });
};

export const fetchCompletedStudentSessions = ({
  batchStudentName,
  pageNumber,
  daysBefore,
}: {
  batchStudentName: string;
  daysBefore: number;
  pageNumber: number;
}) => api.get(`/student_sessions/`, { batchStudent: batchStudentName, daysBefore, page: pageNumber });

export const fetchCatchupSessions = ({
  batchStudentName,
  pageNumber,
  daysBefore,
}: {
  batchStudentName: string;
  daysBefore: number;
  pageNumber: number;
}) => api.get(`/student_sessions/catchup/`, { batchStudent: batchStudentName, daysBefore, page: pageNumber });

export const fetchStudentFeedback = ({ sessionName }: { sessionName: string }) =>
  api.get(`my/completed_sessions/${sessionName}/student-feedback/`);

export const listSessionSchedule = ({ coachUsername }: { coachUsername?: string }) =>
  api.get('schedule-sessions/', { coachUsername }, { timeout: 20000 });

export const scheduleSessions = (sessions: Array<IScheduleSession>) =>
  api.post('schedule-sessions/', { sessions }, { timeout: 20000 });

export const listCreatedSessionsForSubstitution = (params: { providedDate?: string }) =>
  api.get('schedule-sessions/scheduled-sessions-needing-substitution/', params);

export const createdSessionsToBeSubstitutedExists = () =>
  api.get('schedule-sessions/scheduled-sessions-needing-substitution-exist/');

export const listBatchPreviousSessions = (batchName: string) => api.get(`/batches/${batchName}/previous-sessions/`);

export const fetchLastProject = (batchName: string) => api.get(`/batches/${batchName}/last-project/`);

export const fetchBatchCourse = (batchName: string) => api.get(`/batches/${batchName}/course/`);

export const fetchBatchLastCompletedSprint = (batchName: string) =>
  api.get(`/batches/${batchName}/last-completed-sprint/`);

export const changeBatchSuggestions = (props: {
  batchStudentName: string;
  day1IsoWeekday?: number;
  day2IsoWeekday?: number;
  day1Time?: string;
  day2Time?: string;
}) => api.get(`/my/batches/change-batch-suggestions/`, props);

export const changeBatch = (props: {
  batchStudentName: string;
  batchName: string;
  changeDate?: string;
  comment?: string;
}) => api.post(`/my/batches/change-batch/`, props);

export const getNextSessionDetail = ({ sessionName }: { sessionName: string }) =>
  api.get(`my/tutor_sessions/${sessionName}/next-session/`);

export const listCoaches = ({
  q,
  canFetchJuniorCoaches = false,
  fetchSeniorCoaches = false,
  role = COACH_ROLE.COACH,
  freeAt,
  fetchSubstituteCoaches,
  sessionAt,
  pageSize,
}: {
  q: string;
  canFetchJuniorCoaches?: boolean;
  fetchSeniorCoaches?: boolean;
  role?: COACH_ROLES;
  freeAt?: string;
  fetchSubstituteCoaches?: boolean;
  sessionAt?: string;
  pageSize?: number;
}) =>
  api.get(`coaches/`, {
    q,
    canFetchJuniorCoaches,
    fetchSeniorCoaches,
    role,
    freeAt,
    fetchSubstituteCoaches,
    sessionAt,
    pageSize,
  });

export const getCoach = ({ username }: { username: string }) => api.get(`coaches/${username}/`);

export const listMyStudents = ({ q, batchName }: { q: string; batchName?: string }) =>
  api.get(`my/students/`, { q, batchName });

export const fetchUpcomingSeniorCoachSessions = ({
  coach,
  page,
  pageSize,
}: {
  coach?: string;
  page?: number;
  pageSize?: number;
}) => api.get('my/senior-coach-upcoming-sessions/', { coach, page, pageSize });

export interface IAssignNewCoach {
  session_name: string;
  coach_username: string;
}

export const assignANewCoach = (data: IAssignNewCoach) =>
  api.post(`my/senior-coach-upcoming-sessions/${data.session_name}/assign-new-coach/`, data);

export interface IDeleteSession {
  session_name: string;
  send_notification: boolean;
  remarks?: string | null;
}

export const deleteASession = (data: IDeleteSession) =>
  api.post(`my/senior-coach-upcoming-sessions/${data.session_name}/delete-session/`, data);

export const fetchAbsentees = () => api.get('my/batches/absentees/');

export const fetchCompletedSeniorCoachSessions = ({
  batchName,
  sprintName,
  coach,
  daysBefore,
  page,
  pageSize,
}: {
  batchName?: string;
  sprintName?: string;
  coach?: string;
  daysBefore?: number;
  page?: number;
  pageSize?: number;
}) => api.get('my/senior-coach-completed-sessions/', { batchName, sprintName, coach, daysBefore, page, pageSize });

export const fetchMyInsightCalls = ({
  page,
  pageSize,
  booked,
  completedCalls,
  parentUnreachable,
  today,
  upcomingCalls,
  pastCalls,
  batchStudent,
  startAtOrder,
}: {
  page?: number;
  pageSize?: number;
  booked?: string;
  completedCalls?: boolean;
  parentUnreachable?: boolean;
  today?: boolean;
  upcomingCalls?: boolean;
  pastCalls?: boolean;
  batchStudent?: string;
  startAtOrder?: string;
}) =>
  api.get('my/insight-calls/', {
    page,
    pageSize,
    booked,
    completedCalls,
    parentUnreachable,
    today,
    upcomingCalls,
    pastCalls,
    batchStudent,
    startAtOrder,
  });

export interface ICreateInsightCalls {
  batchStudentsNames: Array<string>;
}

export const createInsightCalls = (data: ICreateInsightCalls) => api.post('my/insight-calls/', data);

export const fetchOpsInsightCalls = (data: {
  page: number;
  pageSize: number;
  booked: string | boolean;
  coachUsername?: string;
  batchName?: string;
  startDate?: string;
  endDate?: string;
  completedCalls?: boolean;
  unit?: string;
  callType?: 'renewal' | 'nonRenewal' | 'reschedule';
  sortByReportSentOn?: 'asc' | 'desc';
  seniorCoachUsernames?: string[];
  reschedulingNeeded?: boolean;
  needingSubstitution?: boolean;
}) => api.get('insight-calls-ops/', data);

export const markInsightCallParentUnreachable = (insight_call_name: string, data: { comments?: string }) =>
  api.post(`insight-calls-ops/${insight_call_name}/mark-parent-unreachable/`, data);

export const updateOpsRemarks = (insight_call_name: string, data: { opsRemarks: string }) =>
  api.patch(`insight-calls-ops/${insight_call_name}/update-ops-remarks/`, data);

export const fetchSeniorCoachInsightCalls = ({
  batchName,
  coach,
  daysBefore,
  page,
  pageSize,
  completedCalls,
}: {
  batchName?: string;
  coach?: string;
  daysBefore?: number;
  page?: number;
  pageSize?: number;
  completedCalls?: boolean;
}) => api.get('my/senior-coach-insight-calls/', { batchName, coach, daysBefore, page, pageSize, completedCalls });

export const fetchOnDemandOpsInsight = (data: {
  page: number;
  pageSize?: number;
  coachUsername?: string;
  batchName?: string;
  startDate?: string;
  endDate?: string;
  completedCalls?: boolean;
  callType?: 'on-demand' | 'office-hour';
}) => api.get('on-demand-insight-call-ops/', data);

export const cancelOnDemandCall = (insight_call_name: string, data: { opsRemarks: string }) =>
  api.post(`on-demand-insight-call-ops/${insight_call_name}/cancel-call/`, data);

export const fetchUnsentreportsOpsInsight = ({
  page,
  pageSize,
  coachUsername,
  batchName,
}: {
  page?: number;
  pageSize?: number;
  coachUsername?: string;
  batchName?: string;
}) => api.get('report-ops-insight/', { page, pageSize, coachUsername, batchName });

export const skipCreateNextTutorSession = ({ sessionName, remark }: { sessionName: string; remark: string }) =>
  api.post(`my/tutor_sessions/${sessionName}/skip-create-next-session/`, { remark });

export const deleteTutorSession = ({ session_name, send_notification, remarks }: IDeleteSession) =>
  api.post(`my/tutor_sessions/${session_name}/delete-session/`, { send_notification, remarks });

export const getInsightCallUnitList = () => api.get(`insight-calls-ops/units-list/`);

export const fetchInsightCallRescheduleRequests = (
  page: number,
  pageSize: number,
  rescheduledSessions: boolean,
  coachUsername?: string,
  batchName?: string,
  date?: string,
  unit?: string,
  orderBy?: string,
  sortingOrder?: 'asc' | 'desc',
  seniorCoachUsernames?: string[]
) =>
  api.get('insight-call-sessions-ops/', {
    page,
    pageSize,
    rescheduledSessions,
    coachUsername,
    batchName,
    date,
    unit,
    orderBy,
    sortingOrder,
    seniorCoachUsernames,
  });

export const fetchBatchProjectFeedbackList = (
  batchName?: string,
  coachUsername?: string,
  onlyCertificateProject?: boolean
) => api.get('/my/project/senior-coach-feedback/list-batch/', { batchName, coachUsername, onlyCertificateProject });

export const fetchBatchProjectFeedback = (batchName: string, projectName: string, preview: boolean) =>
  api.get(`/my/project/senior-coach-feedback/batch-projects/`, { batchName, projectName, preview });

export const fetchBatchStudentProjectFeedback = (batchStudentName: string) =>
  api.get(`/my/project/senior-coach-feedback/student-projects/`, { batchStudentName });

export const fetchBatchProjectFeedbackReview = (projectName: string, approved?: boolean, feedback?: string) =>
  api.post(`/my/project/senior-coach-feedback/${projectName}/feedback/`, { approved, feedback });

export const getProjectSubmission = ({ submission }: { submission: string }) =>
  api.get(`/project/submission/${submission}/`);

export const fetchProjectSubmissions = ({
  studentUsername,
  projectName,
  pageNumber,
  pageSize,
}: {
  studentUsername: string;
  projectName: string;
  pageNumber: number;
  pageSize: number;
}) => api.get(`/project/submission/`, { page: pageNumber, pageSize, studentUsername, projectName });

export type SubmissionStatus = 'Submitted' | 'Change Requested' | 'Approved' | 'Rejected' | 'Revision Submitted';

export const fetchProjectSubmissionsNew = ({
  studentUsername,
  projectName,
  pageNumber,
  pageSize,
  submissionStatus,
  batchName,
}: {
  studentUsername?: string;
  projectName?: string;
  batchName?: string;
  pageNumber: number;
  pageSize?: number;
  submissionStatus?: SubmissionStatus;
}) =>
  api.get(`project/submissions-new/`, {
    page: pageNumber,
    pageSize,
    studentUsername,
    projectName,
    submissionStatus,
    batchName,
  });

export const fetchUniqueProjects = ({
  studentUsername,
  pageNumber,
  pageSize,
}: {
  studentUsername: string;
  pageNumber: number;
  pageSize?: number;
}) => api.get(`project/submissions-new/projects/`, { page: pageNumber, pageSize, studentUsername });

export const submitProjectFeedback = ({
  feedback,
  submissionName,
  status,
}: {
  feedback: string;
  submissionName: string;
  status: string;
}) => api.patch(`/project/submission/${submissionName}/`, { feedback, status });

interface IupdateBatchStudentStatus {
  batchStudentName: string;
  reason: string;
  callbackDate?: string;
  reasonDescription?: string;
  churnReason?: string;
  endSubscription?: boolean;
}
export const updateBatchStudentStatus = (data: IupdateBatchStudentStatus) =>
  api.post('/batch_students/update-status/', data);

export const getBatchStudentLatestSprint = (batchStudentName: string) =>
  api.get(`/batch_students/${batchStudentName}/latest-sprint/`);

export const getpendingBatchChangeRequests = (batchStudentName: string) =>
  api.get(`/batch_students/${batchStudentName}/pending-batch-change-requests/`);

export const getStudentBatchName = (batch: string, username: string) =>
  api.get('/batch_students/get_student_detail_batch/', { batch, username });

interface ISessionEvent {
  session_name: string;
  event_type: string;
  student: string;
  student_skill: string;
  event_timestamp: string;
}

export const createSessionEvent = (data: ISessionEvent) => api.post('/blackbox/session-event/', data);

export const getAllBatchProjects = (batch: string) => api.get(`/batches/${batch}/projects/`);

export const listTournamentRequests = (params: {}) => api.get('/ops/tournament-requests/', params);

export const listBatchCreationGroups = (classesPerWeek: number, subscriptionBased: boolean) =>
  api.get('/ops/batches/list-batch-creation/', { classesPerWeek, subscriptionBased });

export const markTournamentRequestCompleted = ({ requestName }: { requestName: string }) =>
  api.post(`/ops/tournament-requests/${requestName}/mark-completed/`);

export const listBatchCreationAvailableCoaches = (params: {}) => api.get('/ops/batches/available-coaches/', params);

export const createBatch = (params: {}) => api.post('/ops/batches/create-batch/', params);

export const createCoachAbsence = (params: {}) => api.post('/coach-absence/', params);

export interface IGetAbsenceCoaches {
  page?: number;
  pageSize?: number;
  todayAndUpcoming?: boolean;
  getSelfLeaves?: boolean;
  upcoming?: boolean;
  past?: boolean;
}

export const getAbsentCoaches = (params: IGetAbsenceCoaches) => api.get('/coach-absence/', params);

export const cancelCoachAbsence = (coachAbsenceName: string) => api.post(`/coach-absence/${coachAbsenceName}/cancel/`);

export const listStudentsCallback = ({
  page,
  pageSize,
  showRenewals,
}: {
  page?: number;
  pageSize?: number;
  showRenewals?: boolean | null;
}) => api.get('/ops/callback-requests/', { page, pageSize, showRenewals });

export const studentCallbackMarkDone = ({ selectedRequest, comment }: { selectedRequest: string; comment: string }) =>
  api.post(`/ops/callback-requests/${selectedRequest}/mark-done/`, { comment });

export const rescheduleCallbackRequest = ({
  selectedRequest,
  comment,
  callbackDate,
}: {
  selectedRequest: string;
  comment: string;
  callbackDate: string;
}) => api.post(`/ops/callback-requests/${selectedRequest}/reschedule/`, { callbackDate, comment });

export const fetchOnboardingFormData = (batchStudentName: string) =>
  api.get(`batch_students/${batchStudentName}/onboarding-form-data/`);

export const changeBatchStudentMergeStatus = (batchStudentName: string, unmergeable: boolean, reason?: string) =>
  api.patch(`batch_students/${batchStudentName}/unmergeable/`, { unmergeable: unmergeable, reason: reason });

export const fetchAnalytics = (startDate: string | null, endDate: string | null) =>
  api.get('/issues/analytics', { startDate, endDate });

export const fetchOpsGroupDetails = () => api.get('issues/ops-group-detail-list/');

export const fetchMyAlerts = () => api.get('my/coaches/alerts/');
export const fetchMyMonthlyStats = () => api.get('my/coaches/monthly-stats/');
export const fetchMyBatchHistory = () => api.get('my/coaches/batch-history/');
export const fetchMyJuniorCoaches = () => api.get('my/coaches/junior-coaches/');

export const listProducts = ({ q }: { q?: string }) => api.get('/payments/products/', { q });

export const listInvoices = ({ q, page, pageSize = 10 }: { q?: string; page?: number; pageSize?: number }) =>
  api.get('/payments/invoices/user-invoices/', { q, page, pageSize });

export const getPaymentUser = ({ q }: { q?: string }) => api.get('/payments/invoices/user-detail/', { q });

export const makePayment = (paymentData: {
  invoice: string | undefined;
  studentUsername: string;
  product: string;
  paymentMethod: string;
  paymentReference: string;
  zohoInvoice?: string;
  amount: string;
  paymentDateTime: string;
  isInstallment: boolean;
}) => api.post('/payments/invoices/make-payment/', paymentData);

export const fetchStudentAttendance = ({ sessionName }: { sessionName: string }) =>
  api.get(`my/completed_sessions/${sessionName}/student-attendance/`);

export const fetchUserSubscriptionPaymentLinks = ({ params }: { params: string[] }) =>
  api.get(`subscriptions/user/payment-links/?params=${params}`);

export const fetchFeedbackBadges = (params: { batchStudentName?: string; pageSize?: number }) =>
  api.get(`feedback-badges/`, params);

export const generateBadgeStudentReport = (pks: string) =>
  api.post(`feedback-badges/generate-student-report/`, { pks });

export const fetchProjectPresentationVideos = (params: { projectName: string; studentUsername: string, page: number }) =>
  api.get('presentation-videos/', params);
