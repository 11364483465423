import { types, Instance } from 'mobx-state-tree';
import InvokerType from './InvokerType';

const RaisedHandValue = types.model({
  last_raised_at: types.maybe(types.number),
  isHandRaised: types.boolean,
});


export const PersistedReactionsStore = types
  .model('PersistedReactionsStore', {
    enableReactions: false,
    addNewReaction: InvokerType,
    removeReactionInvoker: InvokerType,
    reactionsMap: types.map(types.string),
    raisedHandsMap: types.map(RaisedHandValue),
    raiseHandsInvoker: InvokerType,
    putHandDownInvoker: InvokerType,
  })
  .actions((self) => ({
    pushNewReaction: (params: { reaction: string; username: string }) => {
      return self.addNewReaction.invoke(params);
    },
    removeReaction: (username: string) => {
      return self.removeReactionInvoker.invoke({ username });
    },
    toggleFeedbackReaction() {
      self.enableReactions = !self.enableReactions;
    },
    hideFeedbackReaction() {
      self.enableReactions = false;
    },
    showFeedbackReaction() {
      self.enableReactions = true;
    },
    raiseHand: (username: string) => {
      return self.raiseHandsInvoker.invoke({ username });
    },
    putHandDown: (username: string) => {
      return self.putHandDownInvoker.invoke({ username });
    },
  }));

export interface IPersistedReactionsStore extends Instance<typeof PersistedReactionsStore> {}
